// ABSTRACTS
@use './sass/abstracts' as *;

// COMPONENTS
@use './sass/components' as *;

// TYPOGRAPHY
@use './sass/layout/typography' as *;

// MAINPAGES
@use './sass/layout/mainpages' as *;

// SECTIONS
@use './sass/layout/sections' as *;

// ||||| FONTS ||||

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.App {
  text-align: center;
  font-family: 'Raleway';
  padding-top: 55px;
}

html {
    font-size: 62.5%;
}

// ||||| GENERAL |||||


@media screen and (max-width: 576px){
  .container {
    padding-left: calc(var(--bs-gutter-x));
    padding-right: calc(var(--bs-gutter-x));
  }
}