@use '../../abstracts' as *;

// ||||| CONTADOR |||||

.counter {

    &.container {
      padding: 0;
      padding-bottom: 0;
  
      & .row {
        margin: 0;
  
        & .counter-cell {
          height: 100px;
          margin: 10px 0;
          border-left: $grey 3px solid;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding: 0;
          row-gap: 6px;
      
          & .counter-cell-title {
            font-family: 'Raleway';
            font-size: 1.2rem;
            color: $yellow;
            font-weight: 900;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            font-family: 'Raleway';
          }
      
          & .counter-cell-value {
            font-family: 'Montserrat';
            font-size: 2.5rem;
            font-weight: 50;
            display: flex;
            justify-content: center;
            align-items: center;
          }
      
          &:nth-child(1) {
            border-left: none;
            margin-left: -3px;
          }
        }
      }
    }
  
    
  }