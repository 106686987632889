.servicos {

    & .slider {
        margin: 0 20px;
        overflow: "hidden";
        padding: 2rem 0;
      
      
        & img {
            width: 30%;
            border-radius: 0;
        }
        
    }
      
    & .react-multi-carousel-list {
        padding: 0rem 0 2rem 0;
    }
    
    & .slider-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
    
        &--left {
            left: 12px;
        }
    
        &--right {
            right:12px;
        }
    }

    & .slider-servicos-title {
        font-size: 2rem;
        font-weight: 500;
        margin-top: 20px;
        text-align: center;
    }
    
}