.wip-container {
    & img {
      width: 100%;
    }

    height: calc(100vh - 110px);
}


.wip-container-desktop {
  & img {
    height: 50%;
  }

  height: calc(100vh - 110px);
}