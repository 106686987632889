.section-alumni {
    padding-left: 0 !important;
    padding-right: 0 !important;

    .title {
        padding-right: calc(var(--bs-gutter-x)* 0.5);
        padding-left: calc(var(--bs-gutter-x)* 0.5);
        margin-bottom: 50px;
    }
}

.slider {
    margin: 0 20px;
    overflow: "hidden";
    padding: 2rem 0;
  
  
    & img {
        width: 100%;
        border-radius: 50%;
    }
    
}
  
.react-multi-carousel-list {
    padding: 0rem 0 2rem 0;
}

.slider-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;

    &--left {
        left: 22%;
    }

    &--right {
        right: 22%;
    }
}

.alumni-description {

    &-name {
        text-align: center;
        font-size: 3.2rem;
        font-weight: 900;
        text-transform: uppercase;
    }

    &-role {
        text-align: center;
        font-size: 2rem;
        font-weight: 700;
    }

    &-text {
        text-align: center;
        font-size: 1.4rem;
    }
}