@use '../../abstracts' as *;

// TEXT STYLE
.title {
    text-align: start;
    font-weight: 800;
    font-size: 3.2rem;
    margin-bottom: 40px;
    padding-top: 40px;
  
    &::after {
      content: "";
      width: 100px;
      height: 10px;
      background-color: $yellow;
      display: block;
    }
  }
  
h4 {
  color: $yellow;
  font-weight: 700;
  font-size: 2rem;
  text-align: start;
}

.super-title-over {
    text-align: start;
    font-weight: 800;
    font-size: 3.2rem;
    padding-top: 40px;
}

.super-title-under {
  text-align: start;
  font-weight: 800;
  font-size: 4.5rem;
  color: $yellow;
  margin-bottom: 35px;

  &-recrutamento {
    text-align: start;
    font-weight: 800;
    font-size: 3.2rem;
    color: $yellow;
    margin-bottom: 35px;
  }
}

.dark-subtitle {
  color: $black;
  margin-bottom: 12px;
}