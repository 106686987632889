@use '../abstracts' as *;

.list {
  ol {
    list-style: none;
    counter-reset: custom-counter;
    width: 100vw;
    padding: 0;
    margin-left: calc(-1 * var(--bs-gutter-x));
    
    li {
      position: relative;
      padding: 20px 20px 12px 40px;
      text-align: left;
      font-size: 1.4rem;
      
      &:before {
        content: counter(custom-counter);
        counter-increment: custom-counter;
        position: absolute;
        left: 0;
        color: $yellow;
        font-weight: bold;
        font-size: 2rem;
        margin-left: 20px;
      }
      
      &:nth-child(odd) {
        background-color: $grey;
        color: $white;
      }
    }
  }
}