@use 'colors' as *;

.mt-50 {
    margin-top: 50px;
}

.mt-40 {
    margin-top: 40px;
}

.mb-40 {
margin-bottom: 40px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-30 {
margin-bottom: 30px;
}

.pt-50 {
padding-top: 50px;
}

.pb-30 {
padding-bottom: 30px;
}

.pb-50 {
padding-bottom: 50px;
}

.pb-100 {
    padding-bottom: 100px;
}

.yellow-text {
    color: $yellow;
    font-weight: bold;
}