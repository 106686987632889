@use '../abstracts' as *;

// BLA BUTTON
.btn {
  font-family: 'Raleway';
  font-weight: bold;
  font-size: 1.8rem;
  width: 65%;
  padding: 12px 0;
  border: $yellow 3px solid;
  background-color: $white;
  color: $grey;
  border-radius: 7px;
  text-wrap: nowrap;

  &:hover {
    border: $yellow 3px solid;
    background-color: $white;
    color: $grey;
  }

  &:active {
    background-color: $grey !important;
    color: $white !important;
    border-color: $yellow !important;
  }
}
  
  .btn.dark {
    background-color: $black;
    color: $white;

    &:active {
      background-color: $white !important;
      color: $grey !important;
      border-color: $yellow !important;
    }
  }