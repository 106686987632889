// ||||| HOMEPAGE |||||

.homepage {
    margin: 0;
    padding: 0;
    overflow: hidden;

    & .container {
        padding-bottom: 50px;
    }
}